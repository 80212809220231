/*
 * Servizio che si occupa della gestione delle Survey
*/
module SurveyService {
    let app = angular.module("app");

    app.factory("SurveyService", ["$resource", "GlobalApplicationData", ($resource: ng.resource.IResourceService, GlobalApplicationData: any) => {
        let serv = {
            // Recupera tutte le Survey dell'utente loggato
            getSurveys: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-surveys/:referenceId', {
                referenceId: "@referenceId"
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),

            // Recupera la struttura della survey
            getFlatSurveyBySurveyId: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-flat-survey-by-surveyId/:surveyId', {
                surveyId: "@surveyId"
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),

            // Recupera i dettagli di una specifica Survey
            getSurveyBySurveyId: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-survey-by-surveyId/:surveyId', {
                surveyId: "@surveyId"
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),

            // Recupera le survey di un'edizione
            getSurveysByReferenceId: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-surveys-by-referenceId/:referenceId', {
                referenceId: "@referenceId"
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),

            // Elimina il template di una survey
            deleteSurveyTemplate: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/delete-survey-template', {
            }, {
                query: {
                     method: 'DELETE',
                     isArray: false
                }
            }, {
                cancellable: true
            }),
            // Recupera i template delle survey associate ad un Item
            getSurveysTemplateByReferenceId: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-surveys-template-by-reference-id/:referenceId', {
                referenceId: "@referenceId"
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),

            // Recupera il template flat di una survey
            getFlatSurveyTemplateBySurveyTemplateId: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-flat-survey-template-by-surveyTemplateId/:surveyId', {
                surveyId: "@surveyId"
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),

            // Recupera il template di una survey
            getSurveyTemplateBySurveyId: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-survey-template-by-surveyId/:surveyId', {
                surveyId: "@surveyId"
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),

            // Salva numero domande personalizzabili dall'utente
            addSurveyRandomGroupQuestionsLimit: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/add-or-update-survey-random-group-questions-limit', {
            }, {
                    query: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),
            

            // Aggiorna la Survey
            updateSurvey: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/update-survey', {
            }, {
                query: {
                     method: 'POST',
                     isArray: false
                }
            }, {
                cancellable: true
            })
        }; 
        return serv;
    }]);
}